import React from 'react';
import styled from 'styled-components';
import { Footnote, DefinitionBlock  } from '../../util/typography';

const Container = styled.div`
    margin-top: clamp(20px, 2.5vw, 30px);
`;

// @todo implement theme
const NotationBlock = props => {
    return (
        <Container>
            {props.footnotes && props.footnotes.map((item, index) => {
                return <Footnote key={index} label={item.label} text={item.text} theme={props.theme} />;
            })}

            {props.definitions && <DefinitionBlock text={props.definitions} theme={props.theme} />}

        </Container>
    );
};

export default NotationBlock;
