import styled, { css } from 'styled-components';
import { createResponsiveFontRules } from '../functions';
import * as colors from '../../util/colors';
import { getLinkStyles } from './Text';
import { DEFAULT_FONT_FAMILY } from './base';

// 100: thin
// 300: light
// 400: regular
// 500: medium
// 600: semibold
// 700: bold
// 800: extrabold
// 900: black

//fontSizeAndLineHeight: [ fontSizeAt320px, lineHeightAt320px, (fontSizeAt1080px, lineHeightAt1080px)]
//provide all four parameters to get a set of responsive rules for font size and line height between 320px and 1080px
//omitting the second two parameters creates static font rules for font-size and line-height

const headingTypeMap = {
    XL: {
        fontSizeAndLineHeight: [46, 46, 60, 64],
        fontWeight: '300',
    },
    1: {
        fontSizeAndLineHeight: [26, 30, 50, 56],
        fontWeight: '400',
    },
    2: {
        fontSizeAndLineHeight: [24, 28, 40, 48],
        fontWeight: '400',
    },
    3: {
        fontSizeAndLineHeight: [22, 28, 30, 38],
        fontWeight: '400',
    },
    4: {
        fontSizeAndLineHeight: [20, 26, 24, 32],
        fontWeight: '400',
    },
    5: {
        fontSizeAndLineHeight: [20, 26],
        fontWeight: '500',
    },
    6: {
        fontSizeAndLineHeight: [18, 24],
        fontWeight: '500',
    },
    7: {
        fontSizeAndLineHeight: [16, 24],
        fontWeight: '500',
    },
    subtitle: {
        fontSizeAndLineHeight: [20, 24],
        fontWeight: '300',
    },
};

export const getHeadingStylesByType = type => css`
    display: block;
    font-family: ${DEFAULT_FONT_FAMILY};
    font-weight: ${headingTypeMap[type].fontWeight};
    ${createResponsiveFontRules(...headingTypeMap[type].fontSizeAndLineHeight)};
    color: ${({ theme }) => (theme === 'light' ? colors.white : colors.ink)};

    ${getLinkStyles}

    sup {
        font-size: max(42%, 10px);
        vertical-align: top;
        position: relative;
        top: 0.8em;
    }
`;

export const H1 = styled.h1`
    ${getHeadingStylesByType('1')}
`;

export const H2 = styled.h2`
    ${getHeadingStylesByType('2')}
`;

export const H3 = styled.h3`
    ${getHeadingStylesByType('3')}
`;

export const H4 = styled.h4`
    ${getHeadingStylesByType('4')}
`;

export const H5 = styled.h5`
    ${getHeadingStylesByType('5')}
`;

export const H6 = styled.h6`
    ${getHeadingStylesByType('6')}
`;

export const H7 = styled.span`
    ${getHeadingStylesByType('7')}
`;

export const XL = styled.span`
    ${getHeadingStylesByType('XL')}
`;

export const Subtitle = styled.span`
    ${getHeadingStylesByType('subtitle')}
`;
